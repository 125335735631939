import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/collapse';
import '../scss/application.scss'

// Used for DELETE links and simple confirmation dialogs
import Rails from 'rails-ujs'

Rails.start();

require.context('../images', true)
